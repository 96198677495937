import React, { useEffect, useState } from "react";
import Primesale from "../../Layouts/Deal/Primesale.js";
import Primedeal from "../../Layouts/Deal/Primedeal.js";
import { useParams } from "react-router-dom";
import Footerinfo from "../../Home/Footerinfo";
import CategoriesSection from "../Home/CategoriesSection.js";
import { useSelector, useDispatch } from "react-redux";
import Dailydealifs from "../../Tools/Dailydealifs/index.js";
import { getDealsContent,clearAction,  } from "../../../Reducer/dealsSlice.js";
import { getsearchedDeals } from "../../../Reducer/dealsSlice.js";
import { useSearchParams } from 'react-router-dom';
import Pagination from "../../Tools/Pagination/index.js";
import LoadingOverlay from "../../Tools/Loading/index.js";
export default function SuperDealsPage() {
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const dealscontent = useSelector((state) => state.dealscontent.content);
    const dealssuccessData = useSelector((state) => state.dealscontent.success);
    const [loading, setLoading] = useState(true);
    const [dailydeals, setdailyDeals] = useState("");

    useEffect(() => {
          setLoading(true);
        let formData = {
            superdeal: 1,
            limit: 20,
            page: currentPage,
          };
          dispatch(getDealsContent(formData));
          }, [currentPage]);

  useEffect(() => {
    if (dealscontent && dealssuccessData) {
      setdailyDeals(dealscontent);
      setLoading(false);
      dispatch(clearAction());
    }
  }, [dealssuccessData]);


  const handlePageChange = (page) => {
    if (page >= 1 && page <= 4) {
      window.scrollTo({top: 0});
      setCurrentPage(page);
    }
  };
  return (
    <>
      <CategoriesSection />
      <section class="shop deals_shop">
      <div className="container">
        <div className="row">
        {loading ? (
            <LoadingOverlay/> 
          ) : (
            dailydeals.length > 0 &&
            dailydeals.map((ele) => <Dailydealifs deal={ele}></Dailydealifs>) )
          }
        </div>

        <Pagination currentPage={currentPage} totalPages={4} onPageChange={handlePageChange}></Pagination>
      </div>

      </section>
      <Footerinfo></Footerinfo>
    </>
  );
}
