import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { clearStatus, loginForm } from '../../../Reducer/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { FacebookProvider, useLogin } from 'react-facebook';
import {LoginSocialTwitter} from 'reactjs-social-login';
import { useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Login({ show, onClose }) {
    const loginrespodata = useSelector(state => state.auth.loginrespodata);
    const successdeta = useSelector(state => state.auth.success);
    const errordata = useSelector(state => state.auth.error);
    const [ isvalidEmail, setIsValidEmail] = useState(true);
    const [ isvalidPassword, setIsValidPassword] = useState(true);
    const { login, status, isLoading, error} = useLogin();
    const dispatch = useDispatch();
    const navigate = useNavigate();
   const [showForgotPasswordModal, setShowForgotPasswordModal] =
     useState(false);
    const [sidebardt, sidebarstate] = useState([]);
    const [isActive, setIsActive] = useState(false);
    
  const openForgotPasswordModal = () => {
    onClose(); // Close login modal
  };

  const closeForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

    const [logincred, setStatelogin] = useState({
        email: "",
        password: "",
    });
   
    useEffect(() => {
    }, [])


    if (errordata == 500 && loginrespodata) {
    
      // alert(loginrespodata.responseData.message);
      toast(loginrespodata.responseData.message);
      dispatch(clearStatus());
    } else if (successdeta == true && loginrespodata) {
     
      localStorage.setItem(
        "user",
        JSON.stringify(loginrespodata.responseData.data)
      );
      // localStorage.setItem('token', response.token);
      toast(loginrespodata.responseData.message);

      setStatelogin({
        email: "",
        password: "",
      });
      onClose();
      dispatch(clearStatus());
      
      navigate("/");
    }
    // if (successdeta && successdeta.status) {
    //     console.log("testtt", successdeta.status)
    // }


    // console.log("**********",loginrespodata.responseData)
    const handleChange = (e) => {
        const { name, value } = e.target
        if(name === 'email' ){
            setStatelogin(prevState => ({
                ...prevState,
                [name]: value
            }));
            setIsValidEmail(validator.isEmail(value));
        }
        else if(name === 'password' ){
            setStatelogin(prevState => ({
                ...prevState,
                [name]: value
            }));
            setIsValidPassword((validator.isLength(value, { min: 8 })));
        }
    }

    const googlelogin = useGoogleLogin({
        onSuccess: tokenResponse => console.log(tokenResponse),
      });

    async function facebooklogin() {
        try {
          const response = await login({
            scope: 'email',
          });
    
          console.log(response.status);
        } catch (error) {
          console.log(error.message);
        }
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!isvalidEmail ){
            toast.error("Please Enter Valid Email Address");
        }
        if(logincred.email === ''){
            toast.error("Email Address Can't be empty");

        }
        if(!isvalidPassword){
            toast.error("Password must be atleast 8 character long");
        }
        if(logincred.password === ''){
            toast.error("Password Can't be empty");
        }
        if(isvalidEmail && isvalidPassword){
        let formdata = new FormData();
        formdata.append('email', logincred.email);
        formdata.append('password', logincred.password);
        dispatch(loginForm(formdata));
        }
    }
    return (
      <div
        class={`modal fade ${show ? "show" : ""}`}
        id="exampleModalsign"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered loginpage">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-body p-0">
              <div class="form-signin">
                <form onSubmit={handleSubmit}>
                  <h2>Sign In</h2>
                  <div class="form-group">
                    <input
                      type="email"
                      onChange={handleChange}
                      name="email"
                      value={logincred.email}
                      class="form-control"
                      placeholder="Enter your email ID"
                      required=""
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      name="password"
                      value={logincred.password}
                      onChange={handleChange}
                      class="form-control"
                      placeholder=" Password"
                      required=""
                    />
                  </div>
                  <button
                    class="btn btn-lg btn-warning btn-block"
                    type="submit"
                  >
                    Login
                  </button>
                  <div class="row checkbox text-left">
                    <div class="col-6">
                      <label>
                        <input type="checkbox" value="remember-me" /> Remember
                        me
                      </label>
                    </div>
                    <div class="col-6 text-right">
                      <a
                        href=""
                        data-bs-toggle="modal"
                        data-bs-target="#forgotpassword"
                        onClick={openForgotPasswordModal}
                      >
                        Forgot Your Password ?
                      </a>
                    </div>
                  </div>
                  <h6>Or Signin with</h6>
                  <ul class="social">
                    <li>
                      <a href="#" onClick={facebooklogin}>
                        <img src="assets/images/fb.png" alt="icon" /> Facebook
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={googlelogin}>
                        <img src="assets/images/google.png" alt="icon" /> Google
                      </a>
                    </li>
                    <LoginSocialTwitter
                      client_id="LDz5aRioiaSt33xLKOI7zqJr0"
                      // client_secret = "EdX1g2ao2nrBoYdqLcWxlG5IPMYZsKEC6XNnY2oagqsY2zqF1p"
                      // client_secret={process.env.REACT_APP_TWITTER_V2_APP_SECRET || ''}
                      redirect_uri="http://localhost:3000/callback"
                      // onLoginStart={onLoginStart}
                      // onLogoutSuccess={onLogoutSuccess}
                      //     onResolve={
                      //         console.log("working")
                      //     //     ({ provider, data }) => {
                      //     //     setProvider(provider);
                      //     //     setProfile(data);
                      //     // }
                      // }
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <li>
                        <a href="#">
                          <img src="assets/images/tw.png" alt="icon" /> Twitter
                        </a>
                      </li>
                    </LoginSocialTwitter>
                  </ul>
                  <p class="mb-0 text-center">
                    Don’t have an account ?{" "}
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                      href=""
                      onClick={openForgotPasswordModal}
                    >
                      Sign Up
                    </a>
                  </p>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

}






