import React, { useEffect, useState } from "react";
import Primesale from "../../Layouts/Deal/Primesale.js";
import Primedeal from "../../Layouts/Deal/Primedeal.js";
import { useParams } from "react-router-dom";
import Footerinfo from "../../Home/Footerinfo";
import CategoriesSection from "../Home/CategoriesSection.js";
import { useSelector, useDispatch } from "react-redux";
import Dailydealifs from "../../Tools/Dailydealifs/index.js";
import { getDealsContent,clearAction,  } from "../../../Reducer/dealsSlice.js";
import { getsearchedDeals } from "../../../Reducer/dealsSlice.js";
import { useSearchParams } from 'react-router-dom';
import Pagination from "../../Tools/Pagination/index.js";
import LoadingOverlay from "../../Tools/Loading/index.js";
export default function StoreDealsPage() {
    const {id} = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get("search") || "");
    const dispatch = useDispatch();
    const dealscontent = useSelector((state) => state.dealscontent.content);
    const dealssuccessData = useSelector((state) => state.dealscontent.success);
    const [loading, setLoading] = useState(true);
    const [storedeals, setstoreDeals] = useState("");
    useEffect(() => {
        const search = searchParams.get("search") || "";
        setSearchQuery(search);
      }, [searchParams]);
    useEffect(() => {
      setLoading(true);
        let formData = {
            limit: 20,
            page: currentPage,
            store_id: id
          };
          dispatch(getDealsContent(formData));
          }, [id, currentPage]);

  useEffect(() => {
    if (dealscontent && dealssuccessData) {
      setstoreDeals(dealscontent);
      setLoading(false);
      dispatch(clearAction());
    }
  }, [dealssuccessData]);
  const handlePageChange = (page) => {
    if (page >= 1 && page <= 4) {
      window.scrollTo({top: 0});
      setCurrentPage(page);
    }
  };
  
  return (
    <>
      <Primedeal></Primedeal>
      <Primesale></Primesale>
      <CategoriesSection />
      <section class="shop deals_shop">
      <div className="container">
        <div className="row">
          {loading ? (
            <LoadingOverlay /> 
          ): (
              storedeals.length > 0  &&
              storedeals.map((ele) => <Dailydealifs deal={ele}></Dailydealifs>
            )
          )
            }
        </div>
        <Pagination currentPage={currentPage} totalPages={4} onPageChange={handlePageChange}></Pagination>

      </div>
      </section>
      <Footerinfo></Footerinfo>
    </>
  );
}
