import React, { useEffect, useState } from "react";
import Primesale from '../../Layouts/Deal/Primesale.js';
import Primedeal from '../../Layouts/Deal/Primedeal.js';
import Footerinfo from "../../Home/Footerinfo";
import Dailydealdayifs from '../../Home/Dealday';
import DealDay from '../../Home/Dealday.js';
import { Link } from "react-router-dom";
import {
  FetchNewsArticleContent,
  clearAction
} from "../../../Reducer/newsarticleSlice.js";
import { useDispatch, useSelector } from "react-redux";
export default function NewsArticle() {
  const [newsarticle, setNewsArticle] = useState([]);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(FetchNewsArticleContent());
    }, []);
    const newsArticleContent = useSelector(state => state.articlecontent.content);
    const error = useSelector(state => state.articlecontent.error);
    const success = useSelector(state => state.articlecontent.success);
  useEffect(() => {
    if (success && newsArticleContent) {
      setNewsArticle(newsArticleContent);
      dispatch(clearAction());
    }
  }, [success]);
    return (
      <>
        <section class="safety_tips news_articles">
          <div class="container">
            <h6>
              <Link to="/">Home</Link> &lt; News & Articles Listings
            </h6>
            <div class="row mt-4">
              <div class="col-lg-8 col-xxl-9">
                <div class="articles_set">
                  <div class="row">
                    {newsarticle.map((ele) => {
                      return (
                        <>
                          <div class="col-sm-6 mb-4">
                            <div class="set">
                              <img
                                // src={
                                //   "https://www.gstatic.com/webp/gallery3/1.sm.png"
                                //   // https://images.Indiafreestuff.in/uploads/news/thumb/news_1690299181_25318.jpg
                                // }
                                src={ele.images[0]}
                                class="w-100"
                                alt="banner"
                              />
                              <h3>{ele.title}</h3>
                              {/* <p>Amazon is one of the best and most popular online shopping site of India. Amazon has more than thousands number of top brands and more than millions number of products in their site.</p> */}
                              {/* <p>Amazon is very popular for the mobile abd accessories products in theur site. They have so many top branded Smartphones and latest collections. Amazon has so many offers of buying a smartphone, that are like bank offer, EMI offer and many more.</p> */}
                              <div class="row">
                                <div class="col-6">
                                  <h6>
                                    <i class="fa-regular fa-user"></i> By:{" "}
                                    {ele.author}
                                  </h6>
                                </div>
                                <div class="col-6">
                                  <h6>
                                    <i class="fa-regular fa-clock"></i>{" "}
                                    {ele.created}
                                  </h6>
                                </div>
                              </div>
                              {/* <a class="btn" href="#">Read More</a> */}
                              <Link
                                to={`/newsarticle/${ele.id}`}
                                class="btn"
                              >
                                Read More
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-xxl-3">
                <div class="deals_shop">
                  <h3>Deals Of The Day</h3>
                  <DealDay />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footerinfo></Footerinfo>
      </>
    );
}