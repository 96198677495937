import { Link } from "react-router-dom";
import Like from "../Like";
import FavoriteIcon from "../FavoriteIcon";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addToFavorites,
  clearAction,
  removeFromFavorites
} from "../../../Reducer/favoriteSlice";
import Dislike from "../Dislike";
import { update } from "firebase/database";
const Dailydealifs = ({ deal }) => {
  const dispatch = useDispatch();
  const userid = JSON.parse(localStorage.getItem("user"))?.id || null;
  const [isFavorite, setIsFavorite] = useState(false);
  const [favid, setFavId] = useState(null);
  useEffect(() => {
    setFavId(null)
  }, [deal]);
    const FavoritesuccessData = useSelector(
      (state) => state.favoritedeals.addsuccess
    );
   const FavoriteRemovesuccessData = useSelector(
     (state) => state.favoritedeals.removesuccess
   );
    const favoritedealContent = useSelector(
      (state) => state.favoritedeals.favoritedealcontent
    );
    const FavoritefailureData = useSelector(
      (state) => state.favoritedeals.error
    );

    const FavoriteRespoData = useSelector(
      (state) => state.favoritedeals.content
    );
    const updateLocalStorage = (id) => {
      const favdeals = JSON.parse(localStorage.getItem('favdeals')) || [];
      favdeals.push(id);
      localStorage.setItem('favdeals', JSON.stringify(favdeals));
    };
  const UpdateIsFavorite = () => {
      favoritedealContent.map((ele) => {
        if (deal.ID === ele.deal.ID) {
          setIsFavorite(true);
          setFavId(ele.id);
        }
      });
    };
    // const updateIdFromSession = ()=>{
    //   const favids = JSON.parse(localStorage.getItem('favdeals')) || [];
    //   // const favids = localStorage.getItem('favdeals');
    //   console.log(favids);
    //   favids.map((id) => {
    //     if (deal.ID === id) {
    //       setIsFavorite(true);
    //       setFavId(id);
    //     }
    //   });
    // }
  useEffect(() => {
    if (userid) {
      UpdateIsFavorite();
    }
  }, [deal, FavoritesuccessData, FavoriteRemovesuccessData]);
  const onFavoriteClick = async (dealId) => {
    if (userid) {
      if (isFavorite) {
        let formData = {
          favids: favid
        }
        await dispatch(removeFromFavorites(formData));
      }
      else {
        let formData = new FormData();
        formData.append("userid", userid);
        formData.append("dealid", dealId);
        await dispatch(addToFavorites(formData));
      }
         
      }
    else {
      // updateLocalStorage(dealId);
      toast('Please login first');
     }
       
    };
      if (FavoritesuccessData && FavoriteRespoData) {
        if (FavoriteRespoData.status == "success") {
          toast.success(FavoriteRespoData.responseData.message);
          setIsFavorite(true);
          dispatch(clearAction());
        } else {
          toast.error(FavoriteRespoData.responseData.message);
          dispatch(clearAction());
        }
      }
      if (FavoriteRemovesuccessData && FavoriteRespoData) {
        if (FavoriteRespoData.status == "success") {
          toast.success(FavoriteRespoData.responseData.message);
          setIsFavorite(false);
          dispatch(clearAction());
        } else {
          toast.error(FavoriteRespoData.responseData.message);
          dispatch(clearAction());
        }
      }
    // useEffect(() => {
    //   if (FavoritesuccessData && FavoriteRespoData) {
    //     if (FavoriteRespoData.status == "success") {
    //       toast.success(FavoriteRespoData.responseData.message);
    //       setIsFavorite(true);
    //       dispatch(clearAction());
    //     } else {
    //       toast.error(FavoriteRespoData.responseData.message);
    //       dispatch(clearAction());
    //     }
    //   }
    // }, [FavoritesuccessData, FavoriteRespoData]);

    
    return (
      <div class="col-sm-6 col-xl-4 col-xxl-3 mb-3">
        <div class="set">
          <div class="hedset">
            <img src={deal.STOREIMAGE} alt="image" />
          </div>
          <div class="img">
            <a href={deal.COUPON.LINK} target="_blank">
            <span class="apply">Apply Coupon</span>
            </a>
            <span class="sd">SD</span>
            <img src={deal.THUMBIMAGE} alt="img" />
            <ul>
              <li>
                <a onClick={() => onFavoriteClick(deal.ID)}>
                  <i
                    class="fa-regular fa-heart"
                    style={{ color: isFavorite ? "red" : "black" }}
                  ></i>
                </a>
              </li>
              <li>
                <Like id={deal.ID} />
              </li>
              <li>
                <Dislike id={deal.ID}/>
              </li>
              <li>
                <a href="#">
                  <i class="fa-regular fa-message"></i>
                </a>
              </li>
            </ul>
          </div>
          {/* <h4>Brand: Sjyeza</h4> */}
          <div class="content">
            <p>{deal.TITLE}</p>
            <h5>
              Rs. {deal.ORIGNAL_PRICE} <span>Rs. {deal.DISCOUNT_PRICE}</span>
              <span class="per">({deal.DISCOUNT_PERCENTAGE}%Off)</span>
            </h5>
            {/* <a class="btn" href="#">Get Deal</a> */}
            <Link className="btn" to={`/singleproduct/${deal.SLUG}`}>
              Get Deal
            </Link>
          </div>
        </div>
      </div>
    //    <div class="col-sm-6 col-xl-4 col-xxl-3 mb-3">
    //    <div class="set">
    //       <div class="hedset">
    //          <img src="images/amazonblack.png" alt="image"/>
    //       </div>
    //       <div class="img">
    //          <span class="apply">Apply Coupon</span>
    //          <span class="sd">SD</span>
    //          <img src="images/pro1.png" alt="img"/>
    //          <ul>
    //             <li><a href="#"><i class="fa-regular fa-heart"></i></a></li>
    //             <li><a href="#"><i class="fa-regular fa-thumbs-up"></i></a></li>
    //             <li><a href="#"><i class="fa-regular fa-message"></i></a></li>
    //          </ul>
    //       </div>
    //       <h4>Brand: Sjyeza</h4>
    //       <div class="content">
    //          <p>Lorem Ipsum has been the industry's standard</p>
    //          <h5>Rs. 200 <span>Rs. 165</span><span class="per">(43%Off)</span></h5>
    //          <a class="btn" href="#">Get Deal</a>
    //       </div>
    //    </div>
    // </div>
    );
}
export default Dailydealifs;