import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { clearStatus, registerForm } from '../../../Reducer/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { FacebookProvider, useLogin } from 'react-facebook';
import {LoginSocialTwitter} from 'reactjs-social-login';
import { useRef, useCallback } from 'react';

export default function Signup() {
    const registerrespodata = useSelector(
      (state) => state.auth.signuprespodata
    );
    const successdeta = useSelector(state => state.auth.success);
    const errordata = useSelector(state => state.auth.error);
    const [ passwordMatch, setpasswordMatch ] = useState(true);
    const [sidebardt, sidebarstate] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [ isvalidName, setIsValidName] = useState(true);
    const [ isvalidEmail, setIsValidEmail] = useState(true);
    const [ isvalidPassword, setIsValidPassword] = useState(true);
    const { login, status, isLoading, error} = useLogin();
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const [registercred, setStateregister] = useState({
        name:"",
        email: "",
        password: "",
    });
    const dispatch = useDispatch();

    if (errordata == 500 && registerrespodata) {
      toast(registerrespodata.responseData.message);
      dispatch(clearStatus());
    } else if (successdeta == true && registerrespodata) {
      console.log(registerrespodata);
      toast(registerrespodata.responseData.message);
      dispatch(clearStatus());
    }
    useEffect(() => {
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        if(name === 'name' ){
            setStateregister(prevState => ({
                ...prevState,
                [name]: value
            }));
            setIsValidName(validator.isLength(value, { min: 3 }));
        }
        if(name === 'confirmPassword' ){
            setConfirmPassword(value);
        }
        else if(name === 'email' ){
            setStateregister(prevState => ({
                ...prevState,
                [name]: value
            }));
            // console.log(validator.isEmail(value));
            setIsValidEmail(validator.isEmail(value));
        }
        else if(name === 'password' ){
            setStateregister(prevState => ({
                ...prevState,
                [name]: value
            }));
            setIsValidPassword((validator.isLength(value, { min: 8 })));
        }
        else{
        setStateregister(prevState => ({
            ...prevState,
            [name]: value
        }))
    }
    }
    const googlelogin = useGoogleLogin({
        onSuccess: tokenResponse => console.log(tokenResponse),
      });
    async function facebooklogin() {
        try {
          const response = await login({
            scope: 'email',
          });
    
          console.log(response.status);
        } catch (error) {
          console.log(error.message);
        }
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(acceptedTerms){
        if(!isvalidName){
            toast.error("Name must be atleast 3 character long");
        }
        if(registercred.name === ''){
            toast.error("Name Can't be empty");
        }
        if(!isvalidEmail){
            toast.error("Please Enter Valid Email Address");
        }
        if(registercred.email === ''){
            toast.error("Email Address Can't be empty");
        }
        if(!isvalidPassword){
            toast.error("Password must be atleast 8 character long");
        }
        if(registercred.password === ''){
            toast.error("Password Can't be empty");
        }
         if (registercred.password !== confirmPassword) {
            setpasswordMatch(false);
            toast.error("Password and confirm password does not match");
            return;
          }
        if(isvalidName && isvalidPassword && isvalidEmail){

            let formdata = new FormData();
            formdata.append('name', registercred.name);
            formdata.append('email', registercred.email);
            formdata.append('password', registercred.password);
            dispatch(registerForm(formdata));
            setStateregister({
                name: "",
                email: "",
                password: ""
            });
            setAcceptedTerms(false);
            setConfirmPassword("");
        }
    }
    else{
        toast("You must accept the terms and conditions to register.");
    }
        
    }
    return (
      <>
        <div
          class="modal fade"
          id="exampleModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered loginpage">
            <div class="modal-content">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div class="modal-body p-0">
                <div class="form-signin">
                  <form onSubmit={handleSubmit}>
                    <h2>Sign Up</h2>
                    <div class="form-group">
                      <input
                        type="text"
                        name="name"
                        value={registercred.name}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Display Name"
                        required=""
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="email"
                        name="email"
                        value={registercred.email}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Enter your email ID"
                        required=""
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        name="password"
                        value={registercred.password}
                        onChange={handleChange}
                        class="form-control"
                        placeholder=" Password"
                        required=""
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                        class="form-control"
                        placeholder=" Confirm Password"
                        required=""
                      />
                    </div>
                    <button
                      class="btn btn-lg btn-warning btn-block"
                      type="submit"
                    >
                      Register
                    </button>
                    <div class="row checkbox text-left">
                      <div class="col-6">
                        <label>
                          <input
                            type="checkbox"
                            name="acceptedTerms"
                            checked={acceptedTerms}
                            onChange={() => {
                              setAcceptedTerms(!acceptedTerms);
                            }}
                          />{" "}
                          I Agree To The Terms Of Use
                        </label>
                      </div>
                      <div class="col-6 text-right">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                        >
                          Forgot Your Password ?
                        </a>
                      </div>
                    </div>
                    <h6>Or Signin with</h6>
                    <ul class="social">
                      <li>
                        <a href="#" onClick={facebooklogin}>
                          <img src="assets/images/fb.png" alt="icon" /> Facebook
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={googlelogin}>
                          <img src="assets/images/google.png" alt="icon" />{" "}
                          Google
                        </a>
                      </li>
                      <LoginSocialTwitter
                        client_id="LDz5aRioiaSt33xLKOI7zqJr0"
                        // client_secret = "EdX1g2ao2nrBoYdqLcWxlG5IPMYZsKEC6XNnY2oagqsY2zqF1p"
                        // client_secret={process.env.REACT_APP_TWITTER_V2_APP_SECRET || ''}
                        redirect_uri="http://localhost:3000/callback"
                        // onLoginStart={onLoginStart}
                        // onLogoutSuccess={onLogoutSuccess}
                        // onResolve={
                        //     console.log("working")
                        //     ({ provider, data }) => {
                        //     setProvider(provider);
                        //     setProfile(data);
                        // }
                        // }
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <li>
                          <a href="#">
                            <img src="assets/images/tw.png" alt="icon" />{" "}
                            Twitter
                          </a>
                        </li>
                      </LoginSocialTwitter>
                    </ul>
                    <p class="mb-0 text-center">
                      Already a Member ?{" "}
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalsign"
                        href=""
                      >
                        Sign In
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ); 

}