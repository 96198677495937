import React, { useEffect, useState } from "react";
import Primesale from "../../Layouts/Deal/Primesale.js";
import Primedeal from "../../Layouts/Deal/Primedeal.js";
import { useParams } from "react-router-dom";
import Footerinfo from "../../Home/Footerinfo";
import CategoriesSection from "../Home/CategoriesSection.js";
import { useSelector, useDispatch } from "react-redux";
import Dailydealifs from "../../Tools/Dailydealifs/index.js";
import { getDealsContent,clearAction } from "../../../Reducer/dealsSlice.js";
import { FetchCoupons } from "../../../Reducer/couponsSlice.js";
import Pagination from "../../Tools/Pagination/index.js";
import LoadingOverlay from "../../Tools/Loading/index.js";
export default function CouponsPage() {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const couponsData = useSelector((state)=> state.coupons.content);
    const couponssuccessData = useSelector((state)=> state.coupons.success);
    const [coupons, setCoupons] = useState([]);
    const[loading , setLoading] = useState(true);
    const handlePageChange = (page) => {
      if (page >= 1 && page <= 4) {
        setCurrentPage(page);
      }
    };
    useEffect(()=>{
      setLoading(true);
        dispatch(FetchCoupons({
          page: currentPage,
          limit: 20
        }));
    },[currentPage]);
  useEffect(() => {
    if (couponsData && couponssuccessData) {
      setCoupons(couponsData);
      setLoading(false);
      dispatch(clearAction());
    }
  }, [couponssuccessData]);
  
  return (
    <>
      <CategoriesSection />
      <section class="shop deals_shop">
      <div className="container">
        <div className="row">
           {loading ? (
            <LoadingOverlay/> 
          ) : (
            coupons.length > 0 &&
            coupons.map((ele) => <Dailydealifs deal={ele}></Dailydealifs>))
          }
        </div>
        <Pagination currentPage={currentPage} totalPages={4} onPageChange={handlePageChange}></Pagination>
      </div>
      </section>
      <Footerinfo></Footerinfo>
    </>
  );
}
