import React, { useEffect, useState } from "react";
import Primesale from "../../Layouts/Deal/Primesale.js";
import Primedeal from "../../Layouts/Deal/Primedeal.js";
import { useParams } from "react-router-dom";
import Footerinfo from "../../Home/Footerinfo";
import CategoriesSection from "../Home/CategoriesSection.js";
import { FetchDealsByCategories, clearAction } from "../../../Reducer/dealsbyCategorySlice.js";
import { useSelector, useDispatch } from "react-redux";
import Dailydealifs from "../../Tools/Dailydealifs/index.js";
import Pagination from "../../Tools/Pagination/index.js";
import LoadingOverlay from "../../Tools/Loading/index.js";
import ScrollToTop from "../../Tools/ScrollToTop/index.js";
export default function DealsDetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const { id, slug } = useParams();
  const param = useParams();
    const dispatch = useDispatch();
    const [dealsbyCat, setDealsByCat] = useState("");
    const [loading, setLoading] = useState(true);
  const dealsByCategoryContent = useSelector(
    (state) => state.dealsbycategories.content
  );
  const dealsByCategorysuccess = useSelector(
    (state) => state.dealsbycategories.success
  );
  const handlePageChange = (page) => {
    if (page >= 1 && page <= 4) {
      window.scrollTo({top: 0});
      setCurrentPage(page);
    }
  };
    useEffect(() => {
      setLoading(true);
        setDealsByCat("");
          let formData = {};
          if (!isNaN(id)) {
            formData = {
              catID: id,
              page: currentPage,
              limit: 20
            };
          } else {
            formData = {
              slug: id,
              page: currentPage,
              limit: 20
            };
          }
    dispatch(FetchDealsByCategories(formData));
    }, [id, currentPage]);
  useEffect(() => {
    if (dealsByCategoryContent && dealsByCategorysuccess) {
      setDealsByCat(dealsByCategoryContent);
      setLoading(false);
      dispatch(clearAction());
    }
  }, [dealsByCategorysuccess]);
  
  return (
    <>
      <CategoriesSection />
      <section class="shop deals_shop">
      <div className="container">
        <div className="row">

          {loading ? (
            <LoadingOverlay/> 
          ) : (
            dealsbyCat.length > 0 && dealsbyCat.map((ele) => <Dailydealifs deal={ele}></Dailydealifs>)
          )
        }
        </div>
        <Pagination currentPage={currentPage} totalPages={4} onPageChange={handlePageChange}></Pagination>
      </div>
      </section>
      <Footerinfo></Footerinfo>
    </>
  );
}
