import React from "react";
import { useEffect, useState} from "react";

import { fetchRecentComments, clearAction } from "../../Reducer/reccommentSlice";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
export default function Recentcomment() {
    const dispatch = useDispatch();
    const [rcomments, setRComments] = useState([]);
    const rcommentssuccessData = useSelector((state)=> state.recentcomments.success);
    const rcommentscontentData = useSelector((state)=> state.recentcomments.content);
    const [loading , setLoading] = useState(true);
    useEffect(()=>{
        dispatch(fetchRecentComments({limit:5}));
    },[]);
    useEffect(()=>{
        if(rcommentssuccessData && rcommentscontentData){
            setRComments(rcommentscontentData);
            setLoading(false);
        }
    },[rcommentssuccessData])
return (<>
    <div class="col-md-4 col-xxl-3">
        <h2>Recent Comments</h2>
        <ul>
            {!loading && rcomments.map((ele, index)=>(
                <>
                {/* <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li> */}
                <li key={index}>
                    {ele.author} on <Link to={`/singleproduct/${ele.dealslug}`}>{ele.dealtitle}</Link>
                </li>
                </>
            ))}
        </ul>
    </div>
</>)
}
//  setBannerContent(Object.values(response.responseData.data));