import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { changeLanguage, setToken, setUser } from './Reducer/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TostOptions } from './data/content';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FacebookProvider} from 'react-facebook';
import { createRoot } from "react-dom/client";
const user = localStorage.getItem('user')!=='undefined' ? JSON.parse(localStorage.getItem('user')):"";
const token = localStorage.getItem('token') ? localStorage.getItem('token') :"";

if (user && token) {
  store.dispatch(setUser(user));
  store.dispatch(setToken(token));
}

const container = document.getElementById("root");
const root = createRoot(container); 
root.render(
  <GoogleOAuthProvider clientId="648085351503-99db71j3ip9390fpj3asvecm1mi54bmh.apps.googleusercontent.com">
    <FacebookProvider appId="7746971425331949">
    <Provider store={store}>
      <ToastContainer {...TostOptions}/>
      <App />
    </Provider>
    </FacebookProvider>
    </GoogleOAuthProvider>
);
