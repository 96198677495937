import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { articledata } from "../../../data/SingleArticle/data"
import { useDispatch, useSelector } from "react-redux";
import {
  SingleArticleContent,
  clearAction,
} from "../../../Reducer/singlearticleSlice.js";

export default function SingleArticle({id}){
   const dispatch = useDispatch();
   const articledata = useSelector(state => state.singlearticlecontent.content);
   const articlesuccessData = useSelector(state => state.singlearticlecontent.success);
    useEffect(()=>{
         let formdata = new FormData();
            formdata.append('newsid',id);
            dispatch( SingleArticleContent(formdata));
    },[]);
   useEffect(() => {
     if (articlesuccessData) {
       dispatch(clearAction());
     }
   }, [articlesuccessData]);
    
    return(
        <>
        <div class="singlein">
               <h2>{articledata.title}</h2>
               <img src={articledata.images} class="w-100" alt="banner"/>
               <div class="row mt-4">
                  <div class="col-6">
                     <h6><i class="fa-regular fa-user"></i>  By: {articledata.author}</h6>
                  </div>
                  <div class="col-6">
                     <h6><i class="fa-regular fa-clock"></i>  {articledata.created}</h6>
                  </div>
               </div>
               <ul>
                  <li><Link to="#"><img src="/assets/images/fb.png" alt="icon"/></Link></li>
                  <li><Link to ="#"><img src="/assets/images/tw.png" alt="icon"/></Link></li>
                  <li><Link to ="#"><img src="/assets/images/in.png" alt="icon"/></Link></li>
                  <li><Link to ="#"><img src="/assets/images/google.png" alt="icon"/></Link></li>
                  <li><Link to ="#"><img src="/assets/images/pink.png" alt="icon"/></Link></li>
                  <li><Link to ="#"><img src="/assets/images/email.png" alt="icon"/></Link></li>
                  <li><Link to ="#"><img src="/assets/images/share.png" alt="icon"/></Link></li>
               </ul>
              <p dangerouslySetInnerHTML={{ __html: articledata.description}}>
              </p>
            </div>

        </>
    )
}