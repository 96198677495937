import React, { useEffect, useState } from "react";
import DealTypes from "./DealTypes.js";
import ProductOffers from "./ProductOffers.js";
import MoreDeals from "./MoreDeals.js";
import ProductDetails from "./ProductDetails.js";
import DayDeals from "./DayDeals.js";
import { Link } from "react-router-dom";
import Dealday from '../../Home/Dealday.js';
import { getSimilarDeals, clearAction } from "../../../Reducer/SimilardealsSlice.js";
import { useDispatch, useSelector } from "react-redux";
import ProductComments from "./Comments.js";
export default function ProductInfo({product}){
  const dispatch = useDispatch();
  const similarproductData = useSelector((state)=> state.similardeals.content);
  const similarproductsuccess = useSelector((state)=> state.similardeals.success);
  const [loading, setLoading] = useState(false);
  const [similarproducts, setSimilarProducts] = useState([]);
  useEffect(()=>{
    if(product.STOREID){
      const formData = {
        store_id: product.STOREID,
      };
      dispatch(getSimilarDeals(formData));
    }
  },[product])

  useEffect(()=>{
    if(similarproductData && similarproductsuccess){
      setSimilarProducts(similarproductData.slice(0,4));
      dispatch(clearAction());
      setLoading(false);
    }
  },[similarproductData]);
    return (
      <>
        <h6 class="tophd">
          <Link to="/">Home</Link>
          &lt; [Apply Coupon] {product.TITLE}
        </h6>
        <div class="row">
          <div class="col-sm-12">
            <DealTypes></DealTypes>
          </div>
          <div class="col-sm-5 col-xl-4">
            <div class="image">
              <img
                src={product.THUMBIMAGE}
                alt="img"
              />
            </div>
          </div>
          <div class="col-sm-7 col-xl-8">
            <h4>
              <a href={product.COUPON.LINK} target="_blank">[Apply Coupon]</a>{" "}
              {product.TITLE}
            </h4>
            <label>
              <a>
                <i class="fa-regular fa-message"></i> {product.TOTALCOMMENT}
              </a>
              <a>
                <i class="fa-regular fa-heart"></i> {product.TOTALLIKE}
              </a>
            </label>
            <h3>
              Rs. {product.DISCOUNT_PRICE}{" "}
              <span>
                Rs.
                {product.ORIGNAL_PRICE}
              </span>
              <span class="per">({product.DISCOUNT_PERCENTAGE}%Off)</span>
              {product.COUPON.name === 'amazon' && (
                <img src="assets/images/amazonblack.png" alt="image" />
              )}
              <img src={product.STOREIMAGE} alt="image" />
            </h3>
            <ProductOffers offers={product.OFFERS} />
            <a class="btn" href="#">
              Reply
            </a>
            <a className="btn shop_btn" href={product.LINK} target="_blank">
              Shop Now
            </a>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-8 col-xl-9">
            <ProductDetails details={product.CONTENT} />
            <div class="shop single_set">
              {!loading && (
                <>
                 <h3>More Deals From {product.COUPON.NAME}</h3>
              <MoreDeals data={similarproducts}/>
              <ProductComments id={product.ID}/>
                </>
              )}
             
            </div>
          </div>
          <div class="col-sm-4 col-xl-3">
            <DayDeals />
            {/* <Dealday/> */}
          </div>
        </div>
      </>
    );
}