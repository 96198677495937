import { Link } from "react-router-dom";
export default function Coupon({data}){
    return(
        <>
        <div class="col-sm-6 col-xxl-3 mb-3">
                  <div class="set">
                     <div class="hedset">
                        <img src={data.STOREIMAGE} alt="image"/>
                     </div>
                     <div class="img">
                        <img src={data.THUMBIMAGE} alt="img"/>
                     </div>
                     <div class="content">
                        <p>{data.TITLE}</p>
                        {/* <h5>Rs. {data.ORIGNAL_PRICE} <span>Rs. {data.DISCOUNT_PRICE}</span></h5> */}
                        {(data.ORIGNAL_PRICE !== '') ? (
                    <h5>Rs. {data.ORIGNAL_PRICE} <span>Rs. {data.DISCOUNT_PRICE}</span></h5>
                ) : (
                    <h5><span>{data.DISCOUNT_PRICE}</span> </h5>
                )}
                        {/* {data.ORIGINAL_PRICE && !data.ORIGINAL_PRICE.isEmpty() ? (
                    <h5>Rs. {data.ORIGINAL_PRICE} <span>Rs. {data.DISCOUNT_PRICE}</span></h5>
                ) : (
                    <h5><span>{data.DISCOUNT_PRICE}</span> </h5>
                )} */}
                        <Link className="btn" to={`/singleproduct/${data.SLUG}`}>
                         Get Deal
                        </Link>
                     </div>
                  </div>
               </div>
        </>
    )
}