import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dealsContent, fetchSearchedDeals } from "./api";

export const getDealsContent = createAsyncThunk(
  "deals/dealscontent",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await dealsContent(formData);
      return response.data.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const getsearchedDeals = createAsyncThunk(
  "deals/searchdealscontent",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchSearchedDeals(formData);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
)
export const getFeaturedProductContent = createAsyncThunk(
  "deals/featuredproductscontent",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await dealsContent(formData);
      return response.data.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const DealsContentSlice = createSlice({
  name: "dealscontent",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    content: [],
    featureproductcontent: [],
    searchdeal: [],
    searchsuccess: false
  },
  reducers: {
    clearAction: (state) => {
      state.success = false;
      state.error = null;
      state.searchsuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDealsContent.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDealsContent.fulfilled, (state, action) => {
        state.submitting = false;
        state.content = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(getDealsContent.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getsearchedDeals.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.searchsuccess = false;
      })
      .addCase(getsearchedDeals.fulfilled, (state, action) => {
        state.submitting = false;
        state.searchdeal = action.payload;
        state.searchsuccess = true;
        state.error = null;
      })
      .addCase(getsearchedDeals.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.searchsuccess = false;
      })
      .addCase(getFeaturedProductContent.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getFeaturedProductContent.fulfilled, (state, action) => {
        state.submitting = false;
        state.featureproductcontent = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(getFeaturedProductContent.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
  export default DealsContentSlice.reducer;
  export const { clearAction } = DealsContentSlice.actions;