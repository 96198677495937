import React, { useEffect, useState } from "react";
import Dailydealifs from "../Tools/Dailydealifs";
import Coupon from "../Tools/Coupon";
import { FetchCoupons, clearAction } from "../../Reducer/couponsSlice";
import { useDispatch, useSelector } from "react-redux";
export default function Topcoupon() {
    const dispatch = useDispatch();
    const couponsData = useSelector((state)=> state.coupons.content);
    const couponssuccessData = useSelector((state)=> state.coupons.success);
    const [topcoupons, setTopCoupons] = useState([]);
    const[loading , setLoading] = useState(true);
    useEffect(()=>{
        dispatch(FetchCoupons());
    },[]);
    useEffect(()=>{
        if(couponsData && couponssuccessData){
            setTopCoupons(couponsData.slice(0,4));
            setLoading(false);
            dispatch(clearAction());
        }
    },[couponssuccessData]);
return (<>
    <div class="col-md-8 col-xxl-9">
    <h2>Top Coupons & Promo Codes</h2>
    <div class="row">
        {!loading && topcoupons.map((ele, index)=>(
            <Coupon key= {index} data={ele} />
        ))}
    {/* <Dailydealifs></Dailydealifs> */}
    </div>
    </div>
        </>)
}