import React from "react";
import { Link } from "react-router-dom";
const SimilarProductitem = ({ ele}) => {
    return (  
            <div class="set">
                <div class="hedset">
                    <img src={ele.STOREIMAGE} alt="image" />
                </div>
                <div class="img">
                    <img src={ele.THUMBIMAGE} alt="img" />
                </div>
                <div class="content">
                <Link to={`/singleproduct/${ele.SLUG}`}>
                <p>{ele.TITLE}</p>
                    {/* <p>Lorem Ipsum has been the industry's standard</p> */}
                    </Link>
                    <h5>Rs. {ele.ORIGNAL_PRICE}<span>Rs. {ele.DISCOUNT_PRICE}</span></h5>
                </div>
            </div>
        )
}
export default SimilarProductitem ;