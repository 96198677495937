import React from "react";
const Owlselecteditem = ({ data }) => {
    return (  
            <div class="set">
                <div class="hedset">
                    <img src={data.STOREIMAGE} alt="image" />
                </div>
                <div class="img">
                    <img src={data.THUMBIMAGE} alt="img" />
                </div>
                <div class="content">
                    <p>{data.TITLE}</p>
                    {(data.ORIGNAL_PRICE !== '') ? (
                    <h5>Rs. {data.ORIGNAL_PRICE} <span>Rs. {data.DISCOUNT_PRICE}</span></h5>
                ) : (
                    <h5><span>{data.DISCOUNT_PRICE}</span> </h5>
                )}
                </div>
            </div>
        )
}
export default Owlselecteditem;