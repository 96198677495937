import React, { useEffect, useState } from "react";
import validator from "validator";
import { updateprofile } from "../../../Reducer/api";
import { ToastContainer, toast } from "react-toastify";
export default function EditProfile({ user, updateUserProfile }) {
  const [formData, setFormData] = useState(user);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: String(value),
    });
  };
  const handleSubmit = async () => {
    let formdata = new FormData();
    if (!validator.isMobilePhone(formData.phone, "any")) {
      toast.error("Please enter a valid phone number");
      return;
    }
    formdata.append("userid", formData.id);
    formdata.append("phone", formData.phone);
    formdata.append("address", formData.address);
     await updateUserProfile(formdata);
  };
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="editProfile"
        role="tabpanel"
        aria-labelledby="changepassword-tab"
      >
        <div className="row mt-4">
          <div className="col-sm-6">
            <label>Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter name"
              className="form-control"
              value={formData.name}
              readOnly={true}
            />
          </div>
          <div className="col-sm-6">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={formData.email}
              readOnly={true}
            />
          </div>
          <div className="col-sm-6">
            <label>Phone</label>
            <input
              type="text"
              name="phone"
              placeholder="Enter phone number"
              className="form-control"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-sm-6">
            <label>Address</label>
            <input
              type="text"
              name="address"
              placeholder="Enter Address"
              className="form-control"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>

          <div className="col-sm-6">
            <button type="button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
