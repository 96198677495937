import React from "react";
const FrontBanner = ({ value, style, path }) => {
    return (
<div class="col-sm-4 mb-3">
            <div class="set">
               <div class="row">
                  <div class="col-xl-5 col-xxl-6">
                        <img class="proimg w-100" src="assets/images/catg1.png"/>
                  </div>
                  <div class="col-xl-7 col-xxl-6">
                     <div class="apimg">
                        <img src="assets/images/unnamed.png"/>
                     </div>
                     <h6>Deals On 35,000+ Styles</h6>
                     <h3>Starting @ Rs.199</h3>
                        <a class="btn" href="#">
                        <img src="assets/images/persent.png" alt="image"/> Flat 8% Off</a>
                  </div>
               </div>
            </div>
</div>
    )
}
export default FrontBanner;
