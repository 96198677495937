import React, { useEffect, useState } from "react";
import ListItem from "../../Tools/ListItem";
// Import Swiper React components
// Import Swiper styles
import { useSelector, useDispatch } from "react-redux";
import { FetchCategories, clearcatAction } from "../../../Reducer/categoriesSlice";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from "react-router-dom";
export default function CategoriesSection() {
    const categoryContent = useSelector((state)=> state.categories.content);
    const categorysuccessData = useSelector((state) => state.categories.success);
    const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState("true");
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(FetchCategories());
    }, [])
  useEffect(() => {
    if (categorysuccessData && categoryContent) {
      setCategories(categoryContent);
      setLoading(false);
      dispatch(clearcatAction());
    }
  }, [categorysuccessData, categoryContent]);

     const options = {
       loop: true,
       autoplay: false,
       autoplayTimeout: 5000,
       margin: 20,
       responsiveClass: true,
        pagination: false,
       navText: [
         '<span aria-label="Previous">‹</span>',
         '<span aria-label="Next">›</span>',
       ],
       responsive: {
         0: {
           items: 1,
           nav: true,
         },
         576: {
           items: 1,
           nav: true,
         },
         768: {
           items: 2,
           nav: true,
         },
         992: {
           items: 3,
           nav: true,
         },
         1400: {
           items: 10,
           nav: true,
         },
       },
     };
  return (
    <section className="category">
      <div className="container">
        <Swiper
          spaceBetween={20}
          modules={[Navigation, Scrollbar, A11y]}
          // loop={true}
          navigation
          slidesPerView={1}
          breakpoints={{
            576: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            992: { slidesPerView: 4 },
            1400: { slidesPerView: 5 },
          }}
        >
          {!loading &&
            categoryContent.length > 0 &&
            categoryContent.map((ele) => (
              <SwiperSlide key={ele.ID}>
                <Link to={`/dealsdetails/${ele.ID}`}>
                  <div className="CategoryItem">
                    <img src={ele.thumb} alt={ele.TITLE} />
                    <p>{ele.TITLE}</p>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </section>
  );
}
// https://docs.google.com/spreadsheets/d/1tEP_nMC5ZjytQyKGD3L6M4FOiVhJbHdg-Bu6eEOxNLM/edit#gid=0