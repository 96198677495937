import React from "react";
import Dailydealifs from "../Tools/Dailydealifs";
import { useEffect, useState } from "react";
import {
  getFeaturedProductContent,
  clearAction,
} from "../../Reducer/dealsSlice";
import { useDispatch, useSelector } from "react-redux";
export default function FeaturedProduct() {
     const dispatch = useDispatch();
     const featuredproductscontent = useSelector(
       (state) => state.dealscontent.featureproductcontent
     );
     const dealssuccessData = useSelector(
       (state) => state.dealscontent.success
     );
     const [featuredproduct, setfeaturedProduct] = useState("");
     const [loading, setLoading] = useState(false);
     useEffect(() => {
       const formData = {
         featured: 1,
         limit: 5,
         page: 1,
       };
       setLoading(true);
       dispatch(getFeaturedProductContent(formData));
     }, []);

     useEffect(() => {
       if (dealssuccessData && featuredproductscontent) {
         setfeaturedProduct(featuredproductscontent);
         setLoading(false);
         dispatch(clearAction());
       }
     }, [dealssuccessData, featuredproductscontent]);
return (
  <>
    <section class="shop deals_shop featured_products">
      <div class="container">
        <h2>Featured Products</h2>
        <div class="row">
          {featuredproduct &&
            featuredproduct.length > 0 &&
            featuredproduct.map((ele, index) => (<Dailydealifs deal={ele} key={index}></Dailydealifs>))}
          {/* <Dailydealifs></Dailydealifs> */}
        </div>
      </div>
    </section>
  </>
);
}