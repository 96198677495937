import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home,Header, Footer, BasicDetail} from "./Components";
import SingleProduct from './Components/Pages/SingleProduct';
import  Aboutus  from "./Components/Pages/Aboutus";
import Banner from "./Components/Pages/Banner";
import NewsArticle from "./Components/Pages/NewsArticle";
import AuthRoute from './AuthRoute';
import SiteMap from './Components/Pages/SiteMap';
import SafetyTips from './Components/Pages/SafetyTips';
import Stores from './Components/Pages/Stores';
import NewsArticleSingle from './Components/Pages/NewsArticleSingle';
import ScrollToTop from './Components/Tools/ScrollToTop';
import Login from './Components/Layouts/Btmodal/login';
import Signup from './Components/Layouts/Btmodal/signup';
import Profile from './Components/Pages/Profile';
import DealsDetails from './Components/Pages/Dealsdetails';
import TermsConditions from './Components/Pages/Terms&conditions';
import PrivacyPolicy from './Components/Pages/Privacypolicy';
import DealsPage from './Components/Pages/Deals';
import CouponsPage from './Components/Pages/Coupons';
import StoreDealsPage from './Components/Pages/Stores/deals';
import FaqPage from './Components/Pages/Faq';
import ContactusPage from './Components/Pages/Contactus';
import SuperDealsPage from './Components/Pages/Superdeals';
import FavoritesPage from './Components/Pages/Favorites';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/pages/about-us" element={<Aboutus />} />
          <Route exact path="/newsarticleslisting" element={<NewsArticle />} />
          <Route exact path="/banner" element={<Banner />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route
            exact
            path="/Details"
            element={<AuthRoute element={<BasicDetail />} />}
          />
          <Route
            exact
            path="/singleproduct/:slug"
            element={<SingleProduct />}
          />
           <Route
            path="/deals"
            element={<DealsPage />}
          />
          <Route
            path="/faqs"
            element={<FaqPage />}
          />
          <Route
            exact
            path="/coupons"
            element={<CouponsPage/>}
          />
          <Route exact path="/pages/sitemap" element={<SiteMap />} />
          <Route exact path="/pages/Terms-and-Conditions" element={<TermsConditions />} />
          <Route exact path="/pages/Privacy-Policy" element={<PrivacyPolicy />} />
          <Route exact path="/pages/Safety-Tips" element={<SafetyTips />} />
          <Route exact path="/stores" element={<Stores />} />
          <Route exact path="/stores/:id" element={<StoreDealsPage />} />
          <Route
            exact
            path="/newsarticle/:id"
            element={<NewsArticleSingle />}
          />
          <Route exact path="/profile" element={<Profile />} />
          <Route
            exact
            path="/dealsdetails/:id"
            element={<DealsDetails />}
          />
          <Route exact path='/contactus' element={ <ContactusPage/>} />
          <Route exact path="/superdeals" element={<SuperDealsPage/>} />
          <Route exact path="/favorites" element={<FavoritesPage/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
