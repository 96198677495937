import React, { useEffect, useState } from 'react';
import { datas } from '../Sidebar';
import { Link } from 'react-router-dom';
export default function Sidebarview({isActive, activesidebar}) {
    const [sidebardt, sidebarstate] = useState([]);
    // const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        const sidebardata = datas
        sidebarstate(sidebardata)
    }, [])
    // const activesidebar = event => {

    //     setIsActive(current => !current);
    //     // 👇️ toggle isActive state on click
    //     // setIsActive(current => !current);
    // };

    return (
        <div id="mySidebar" className={isActive ? 'sidebar main' : 'sidebar'}>
            <a href="javascript:void(0)" class="closebtn" onClick={activesidebar} ><i class="fa-solid fa-xmark"></i></a>
            {
                sidebardt.length > 0 ?
                    sidebardt.map((sidebardt, index) => (
                        <Link to={sidebardt.src} key={ index} target={sidebardt.target}><img src={sidebardt.image} alt="icon" /> {sidebardt.name}</Link>
                    ))
                    : ""}
        </div>
    )

}